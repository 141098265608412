import { Paper } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormGroup from "@material-ui/core/FormGroup"
import FormLabel from "@material-ui/core/FormLabel"
import Grid from "@material-ui/core/Grid"
import { makeStyles } from "@material-ui/core/styles"
import includes from "lodash/includes"
import without from "lodash/without"
import _filter from "lodash/filter"
import isEmpty from "lodash/isEmpty"
import React, { FC, ChangeEvent } from "react"
import { Filter, Segment, DateFilterOptions, SortOrderOptions } from "./EnvironmentContext"

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
  },
  filterLegend: {
    textAlign: "left",
  },
  filterTitle: {
    textTransform: "uppercase",
  },
}))

type EditorialNotificationsFilterProps = {
  audience: Segment[]
  filter: Filter
  onChange: (filter: Filter) => void
}

export const EditorialNotificationsFilter: FC<EditorialNotificationsFilterProps> = ({ audience, filter, onChange }) => {
  const segments = _filter(audience, (a) => a.type === "Segment")
  const genres = _filter(audience, (a) => a.type === "Genre")

  const allSegments = "All"
  const onSegment = ({ target: { checked, name } }: ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...filter,
      segments: allSegments === name ? [] : checked ? [...filter.segments, name] : without(filter.segments, name),
    })

  const allGenres = "All"
  const genreValue = isEmpty(filter.genres) ? allGenres : filter.genres[0]
  const onGenre = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...filter,
      genres: allGenres === value ? [] : [value],
    })

  const onDateFilterChanged = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...filter,
      dateFilter: value as DateFilterOptions,
    })

  const onSortOrderChanged = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onChange({
      ...filter,
      sortOrder: value as SortOrderOptions,
    })

  const classes = useStyles()

  return (
    <Paper className={classes.root} data-testid="filters-container">
      <Grid container spacing={2} direction="row" justify="center" alignItems="flex-start">
        <Grid item xs={12} sm={3} md={3}>
          <FormControl
            className={classes.filterLegend}
            component="fieldset"
            fullWidth={true}
            data-testid="genres-container"
          >
            <FormLabel className={classes.filterTitle} component="legend">
              Genre
            </FormLabel>
            <RadioGroup value={genreValue} onChange={onGenre}>
              <FormControlLabel control={<Radio />} label="All" value={allGenres} />
              {genres.map(({ id, name }, i) => (
                <FormControlLabel key={i} control={<Radio />} label={name} value={id} />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <FormControl
            className={classes.filterLegend}
            component="fieldset"
            fullWidth={true}
            data-testid="segments-container"
          >
            <FormLabel className={classes.filterTitle} component="legend">
              Segment
            </FormLabel>
            <FormGroup row>
              <Grid item xs={12} sm={12} md={12}>
                <FormControlLabel
                  control={<Checkbox onChange={onSegment} name={allSegments} checked={isEmpty(filter.segments)} />}
                  label="All"
                />
              </Grid>
              {segments.map(({ id, name }, i) => (
                <Grid key={i} item xs={12} sm={12} md={12}>
                  <FormControlLabel
                    key={id}
                    control={<Checkbox onChange={onSegment} name={id} checked={includes(filter.segments, id)} />}
                    label={name}
                  />
                </Grid>
              ))}
            </FormGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <FormControl
            className={classes.filterLegend}
            component="fieldset"
            fullWidth={true}
            data-testid="date-container"
          >
            <FormLabel className={classes.filterTitle} component="legend">
              View
            </FormLabel>
            <RadioGroup value={filter.dateFilter} onChange={onDateFilterChanged}>
              <FormControlLabel control={<Radio />} label="All" value="All" />
              <FormControlLabel control={<Radio />} label="Upcoming" value="Next48Hours" />
              <FormControlLabel control={<Radio />} label="Past/Published" value="Past48Hours" />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <FormControl className={classes.filterLegend} component="fieldset" fullWidth={true}>
            <FormLabel className={classes.filterTitle} component="legend">
              Sort
            </FormLabel>
            <RadioGroup value={filter.sortOrder} onChange={onSortOrderChanged}>
              <FormControlLabel control={<Radio />} label="Newest first" value="Desc" />
              <FormControlLabel control={<Radio />} label="Oldest first" value="Asc" />
            </RadioGroup>
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  )
}
