import { createStyles, Typography } from "@material-ui/core"
import Chip from "@material-ui/core/Chip"
import Grid from "@material-ui/core/Grid"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import { groupBy, toPairs } from "lodash"
import { toDisplayTime, toDisplayDate, toDisplayYear, toDisplayMonthAndDay } from "./dates"
import React, { FC } from "react"
import { EditorialNotification } from "./EnvironmentContext"

const useStyles = makeStyles((theme) => ({
  dateTitle: {
    textAlign: "left",
  },
  year: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(-1),
    textTransform: "uppercase",
    fontWeight: "normal",
    color: theme.palette.grey[500],
  },
  monthAndDay: {
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  subtitle: {
    color: theme.palette.grey[700],
    fontWeight: "bold",
  },
  body: {
    color: theme.palette.grey[700],
    marginTop: theme.spacing(1),
  },
  title: {},
  notificationList: {
    textAlign: "left",
    margin: "auto",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      maxWidth: "80%",
    },
  },
  time: {
    color: theme.palette.grey[700],
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  timeLabel: {
    fontWeight: "bold",
  },
  tms_id: {
    color: theme.palette.grey[500],
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: "right",
  },
  notificationEntry: {
    padding: theme.spacing(2),
  },
  chips: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    backgroundColor: theme.palette.grey[500],
    width: "100%",
    minHeight: "56px",
  },
}))

const StyledNotificationRow = withStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.grey[200],
      marginBottom: theme.spacing(3),
    },
  }),
)(Grid)

const StyledChip = withStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
      borderRadius: "5px",
      textTransform: "uppercase",
    },
  }),
)(Chip)

type EditorialNotificationsListProps = { editorialNotifications: EditorialNotification[] }

export const EditorialNotificationsList: FC<EditorialNotificationsListProps> = ({ editorialNotifications }) => {
  const groupedEditorialNotifications = groupBy(editorialNotifications, ({ date }) => toDisplayDate(date))

  const classes = useStyles()
  return (
    <main role="main">
      {toPairs(groupedEditorialNotifications).map(([day, notifications]) => (
        <article key={day}>
          <Grid container>
            <Grid item xs={12} className={classes.dateTitle}>
              <Typography variant={"h5"} className={classes.year}>
                {toDisplayYear(notifications[0].date)}
              </Typography>
              <Typography variant={"h3"} className={classes.monthAndDay}>
                {toDisplayMonthAndDay(notifications[0].date)}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.notificationList}>
              {notifications.map((n) => (
                <EditorialNotificationRow key={n.id} editorialNotification={n} />
              ))}
            </Grid>
          </Grid>
        </article>
      ))}
    </main>
  )
}

type EditorialNotificationRowProps = {
  editorialNotification: EditorialNotification
}

const EditorialNotificationRow: FC<EditorialNotificationRowProps> = ({
  editorialNotification: { date, message, "tms-id": tmsId, categories, segments, tags },
}) => {
  const audiences = segments.concat(tags)

  const classes: Record<string, string> = useStyles()

  return (
    <article>
      <StyledNotificationRow container>
        <Grid item xs={tmsId ? 6 : 12} className={classes.time}>
          <span className={classes.timeLabel}>Event Alert Time:</span> {toDisplayTime(date)}
        </Grid>
        {tmsId && (
          <Grid item xs={6} className={classes.tms_id}>
            {tmsId}
          </Grid>
        )}
        <Grid item xs={12} className={classes.notificationEntry}>
          {message.title && (
            <Typography variant={"h6"} className={classes.title}>
              {message.title}
            </Typography>
          )}
          {message.subtitle && (
            <Typography variant={"subtitle1"} className={classes.subtitle}>
              {message.subtitle}
            </Typography>
          )}
          {message.body && (
            <Typography variant={"body1"} className={classes.body}>
              {message.body}
            </Typography>
          )}
        </Grid>
        {(categories.length > 0 || audiences.length > 0) && (
          <Grid item className={classes.chips}>
            {!!categories.length && categories.map((c) => <StyledChip key={c} label={c} color="secondary" />)}
            {!!audiences.length && audiences.map((a) => <StyledChip key={a} label={a} color="primary" />)}
          </Grid>
        )}
      </StyledNotificationRow>
    </article>
  )
}
