import React from "react"
import { ErrorCode } from "./errors"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"

type ErrorMessageProps = {
  errorCode: ErrorCode
}

const errorMessages = {
  [ErrorCode.Authorization]: {
    title: "Authorization Failed",
    message: "Please check your client id and api token.",
  },
  [ErrorCode.Api]: {
    title: "Application error",
    message: "Please contact your administrator.",
  },
  [ErrorCode.Brands]: {
    title: "No brands available.",
    message: "Please contact your administrator.",
  },
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorCode }) => {
  const { title, message } = errorMessages[errorCode]

  return (
    <Card>
      <CardContent>
        <Typography paragraph variant="h4" color="textSecondary">
          {title}
        </Typography>
        <Typography variant="subtitle1" color="textSecondary">
          {message}
        </Typography>
      </CardContent>
    </Card>
  )
}
