import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Container,
  IconButton,
  makeStyles,
  MenuItem,
  Popover,
  Snackbar,
  Toolbar,
  Typography,
} from "@material-ui/core"
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import Alert from "@material-ui/lab/Alert"
import React, { useCallback, useState, useEffect, useContext, ReactNode } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import Notification from "react-web-notification"
import { EditorialNotificationsList } from "./EditorialNotificationsList"
import { EditorialNotificationsFilter } from "./EditorialNotificationsFilter"
import { EditorialNotification, EnvironmentContext, Filter, Segment } from "./EnvironmentContext"
import Zoom from '@material-ui/core/Zoom';
import Fab from '@material-ui/core/Fab';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { ErrorMessage } from "./ErrorMessage"
import { ErrorCode } from "./errors"
import Logo from "./images/NBCU.png"

interface IProps {
  children: ReactNode;
  // any other props that come into the component
}

const PAGE_SIZE = 10

const useStyles = makeStyles((theme) => ({
  toolbar: {
    flexGrow: 1,
    flexDirection: "row",
    padding: theme.spacing(0),
    backgroundColor: theme.palette.common.black,
  },
  menuButton: {},
  title: {
    flex: "auto",
    textAlign: "left",
    fontWeight: "bold",
  },
  searchButton: {
    padding: theme.spacing(2),
  },
  filter: {
    [theme.breakpoints.up("lg")]: {
      maxWidth: 1200,
    },
    margin: "auto",
    width: "100%",
  },
  appBar: {
    backgroundColor: theme.palette.common.white,
  },
  img: {
    width: 45,
    [theme.breakpoints.up("sm")]: {
      width: 55,
    },
    padding: theme.spacing(1),
  },
  brands: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  noMore: {
    marginTop: theme.spacing(2),
  },
  loading: {
    marginTop: theme.spacing(2),
  },
  backToTop: {
    position: 'fixed',
    top: theme.spacing(10),
    right: theme.spacing(2),
  },
}))

const defaultFilter: Filter = {
  categories: [],
  segments: [],
  genres: [],
  tags: [],
  dateFilter: "All",
  sortOrder: "Desc",
}

const BackToTop = ({ children }: IProps) => {
  const trigger = useScrollTrigger()

  const classes = useStyles()

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const anchor = ((event.target as HTMLDivElement).ownerDocument || document).querySelector(
      '#back-to-top-anchor',
    );

    if (anchor) {
      anchor.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleClick} role="presentation" className={classes.backToTop}>
        {children}
      </div>
    </Zoom>
  )
}

export const EditorialNotificationsPage = () => {
  const env = useContext(EnvironmentContext)
  const { credentials, getNotifications, getSegments, getBrands, refreshInterval } = env

  const [notifications, setNotifications] = useState<EditorialNotification[]>([])
  const [segments, setSegments] = useState<Segment[]>([])
  const [brands, setBrands] = useState<string[]>([])
  const [brand, setBrand] = useState<string>()
  const [filter, setFilter] = useState<Filter>(defaultFilter)
  const [error, setError] = useState<ErrorCode | undefined>()
  const [hasMore, setHasMore] = useState<boolean>(true)
  const [hasNewNotifications, setHasNewNotifications] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | undefined>(undefined)

  useEffect(() => {
    const fetch = async () => {
      try {
        const b = await getBrands(credentials)
        if (!b.brands || !b.brands.length) {
          setError(ErrorCode.Brands)
          return
        }
        setBrands(b.brands)
        setBrand(b.brands[0])
      } catch ({ errorCode }) {
        setError(errorCode)
      }
    }
    fetch()
    return () => setBrands([])
  }, [credentials, getBrands])

  const fetchNextNotifications = async () => {
    if (!brand) return
    const newNotifications = await getNotifications(credentials, brand, filter, notifications.length, PAGE_SIZE)
    const allNotifications = [...notifications, ...newNotifications]
    const unique = allNotifications.filter(
      (notification, i) => allNotifications.findIndex((n) => n.id === notification.id) === i,
    )

    setHasMore(newNotifications.length >= PAGE_SIZE)
    setNotifications(unique)
  }

  const updateNotifications = (ns: EditorialNotification[]) => {
    setNotifications(ns)
    setHasMore(ns.length >= PAGE_SIZE)
    setHasNewNotifications(false)
  }

  const reloadNotifications = async () => {
    if (!brand) return

    try {
      const ns = await getNotifications(credentials, brand, filter, 0, PAGE_SIZE)
      updateNotifications(ns)
    } catch ({ errorCode }) {
      setError(errorCode)
    }
  }

  useEffect(() => {
    const fetchNotifications = async () => {
      if (!brand) return

      try {
        const ns = await getNotifications(credentials, brand, filter)
        updateNotifications(ns)
      } catch ({ errorCode }) {
        setError(errorCode)
      }
    }

    fetchNotifications()
  }, [brand, credentials, filter, getNotifications])

  useEffect(() => {
    const fetch = async () => {
      if (!brand) return
      try {
        setSegments(await getSegments(credentials, brand))
      } catch ({ errorCode }) {
        setError(errorCode)
      }
    }
    fetch()
  }, [credentials, getSegments, brand])

  useEffect(() => {
    const interval = setInterval(async () => {
      if (!brand) return
      try {
        const newNotifications = await getNotifications(credentials, brand, filter)
        setHasNewNotifications(
          !!newNotifications.length && (!notifications.length || newNotifications[0].id !== notifications[0].id),
        )
      } catch ({ errorCode }) {
        setHasNewNotifications(false)
      }
    }, refreshInterval)
    return () => clearInterval(interval)
  }, [refreshInterval, credentials, brand, filter, getNotifications, notifications])

  const selectBrand = useCallback(
    (selectedBrand: string) => {
      setBrand(selectedBrand)
      setAnchorEl(undefined)
    },
    [setBrand, setAnchorEl],
  )

  const classes = useStyles()

  return error ? (
    <ErrorMessage errorCode={error} />
  ) : (
    <>
      <div id="back-to-top-anchor"></div>
      <AppBar position="sticky" elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <img alt="NBCU Logo" className={classes.img} src={Logo} />
          {brands.length > 1 && (
            <IconButton data-testid="brands-button" onClick={(event) => setAnchorEl(event.currentTarget)}>
              {Boolean(anchorEl) ? (
                <ChevronRightIcon style={{ color: "#FFF" }} />
              ) : (
                <KeyboardArrowDownIcon style={{ color: "#FFF" }} />
              )}
            </IconButton>
          )}
          <Popover
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            anchorReference="anchorPosition"
            anchorPosition={{ top: 112, left: 2 }}
            onClose={() => setAnchorEl(undefined)}
          >
            <div className={classes.brands}>
              <Typography>Select NBCU Mobile App:</Typography>
              <nav role="navigation">
                {brands.map((b, i) => (
                  <MenuItem key={i} onClick={() => selectBrand(b)}>
                    {b === brand && <ChevronRightIcon style={{ color: "#FFF" }} />}
                    {b}
                  </MenuItem>
                ))}
              </nav>
            </div>
          </Popover>
          <Box className={classes.title}>
            <Typography variant="h4" className={classes.title}>
              {brand}
            </Typography>
          </Box>
        </Toolbar>
        <BackToTop>
          <Fab color="secondary" size="large" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </BackToTop>
      </AppBar>

      <Container>
        <div className={classes.filter}>
          <EditorialNotificationsFilter audience={segments} filter={filter} onChange={setFilter} />
        </div>
        <Notification
          askAgain={true}
          title="NBCU Content Distribution Editorial Notifications"
          ignore={!hasNewNotifications}
          options={{
            icon: Logo,
            body: "You have new notifications.",
            renotify: true,
          }}
        />
        <Snackbar open={hasNewNotifications}>
          <Alert
            variant="filled"
            severity="info"
            action={
              <Button onClick={reloadNotifications} color="inherit" size="small">
                RELOAD
              </Button>
            }
          >
            You have new notifications.
          </Alert>
        </Snackbar>

        <InfiniteScroll
          dataLength={notifications.length}
          hasMore={hasMore}
          next={fetchNextNotifications}
          loader={
            <div style={{ height: 100 }}>
              <CircularProgress className={classes.loading} />
            </div>
          }
          endMessage={
            <Typography variant="h6" className={classes.noMore}>
              {"No more notifications"}
            </Typography>
          }
        >
          <EditorialNotificationsList editorialNotifications={notifications} />
        </InfiniteScroll>
      </Container>
    </>
  )
}
