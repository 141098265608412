import axios from "axios"
import { Credentials } from "./credentials"
import {
  DateFilterOptions,
  FetchBrands,
  FetchCategories,
  FetchNotifications,
  FetchSegments,
  FetchTags,
  Filter,
} from "./EnvironmentContext"
import { ErrorCode } from "./errors"
import { toISODate, addDays, startOfDayDisplayed, toCustomISODate } from "./dates"

class SystemError extends Error {
  constructor(public errorCode: ErrorCode) {
    super()
  }
}

export const getBrands: FetchBrands = async (credentials) => get("/api/brands", credentials)

export const getNotifications: FetchNotifications = async (credentials, brand, filter, offset = 0, pageSize = 10) => {
  const ns: any[] = await get("/api/notifications", credentials, {
    brand,
    ...transformFilter(filter),
    offset,
    "page-size": pageSize,
  })
  return ns.map((n) => ({ ...n, date: new Date(n.date) }))
}

export const getTags: FetchTags = async (credentials, brand) => get("/api/tags", credentials, { brand })

export const getCategories: FetchCategories = async (credentials, brand) =>
  get("/api/categories", credentials, { brand })

export const getSegments: FetchSegments = async (credentials, brand) => get("/api/segments", credentials, { brand })

const toDateRange = (dateFilter: DateFilterOptions) => {
  const now = new Date(Date.now())
  
  switch (dateFilter) {
    case "Next48Hours":
      return {
        "start-date": toISODate(startOfDayDisplayed(now)),
        "end-date": toCustomISODate(addDays(now, 2)),
      }
    case "Past48Hours":
      return {
        "start-date": toISODate(startOfDayDisplayed(addDays(now, -1))),
        "end-date": toCustomISODate(addDays(now, 1)),
      }
    case "All":
      return {
        "end-date": toCustomISODate(addDays(now, 2)),
      }
  }
}

const transformFilter = ({ categories, segments, genres, tags, dateFilter, sortOrder }: Filter) => ({
  ...(categories.length && { categories: categories.join(",") }),
  ...(segments.length && { segments: segments.join(",") }),
  ...(genres.length && { genres: genres.join(",") }),
  ...(tags.length && { tags: tags.join(",") }),
  ...toDateRange(dateFilter),
  sort: sortOrder.toLowerCase(),
})

const get = async (url: string, { clientId, apiToken }: Credentials, extraParams = {}) => {
  const params = {
    ...extraParams,
    client_id: clientId,
    api_token: apiToken,
  }

  let response
  try {
    response = await axios.get(url, { params })
  } catch (e) {
    response = e.response
  }

  switch (response && response.status) {
    case 200:
      return response.data
    case 401:
    case 403:
      throw new SystemError(ErrorCode.Authorization)
    default:
      throw new SystemError(ErrorCode.Api)
  }
}
