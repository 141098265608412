import React from "react"
import { getBrands, getCategories, getNotifications, getSegments, getTags } from "./apiClient"
import { Credentials } from "./credentials"

export type FetchNotifications = (
  credentials: Credentials,
  brand: string,
  filter: Filter,
  offset?: number,
  pageSize?: number,
) => Promise<EditorialNotification[]>

export type FetchTags = (credentials: Credentials, brand: string) => Promise<string[]>
export type FetchCategories = (credentials: Credentials, brand: string) => Promise<string[]>
export type FetchSegments = (credentials: Credentials, brand: string) => Promise<Segment[]>
export type FetchBrands = (credentials: Credentials) => Promise<Brands>

export type EditorialNotification = {
  id: string
  date: Date
  message: Record<string, string>
  "tms-id"?: string
  categories: string[]
  tags: string[]
  segments: string[]
}

export type DateFilterOptions = "All" | "Next48Hours" | "Past48Hours"
export type SortOrderOptions = "Asc" | "Desc"
export type Filter = {
  categories: string[]
  segments: string[]
  genres: string[]
  tags: string[]
  dateFilter: DateFilterOptions
  sortOrder: SortOrderOptions
}

export type Segment = {
  id: string
  name: string
  type: string
}

export type Brands = {
  brands: string[]
}

export type Environment = {
  credentials: Credentials
  getNotifications: FetchNotifications
  getCategories: FetchCategories
  getSegments: FetchSegments
  getTags: FetchTags
  getBrands: FetchBrands
  refreshInterval: number
}

export const environment: Environment = {
  credentials: { clientId: "", apiToken: "" },
  getNotifications,
  getCategories,
  getSegments,
  getTags,
  getBrands,
  refreshInterval: 30000,
}

export const EnvironmentContext = React.createContext<Environment>(environment)
