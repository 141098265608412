export type Credentials = {
  clientId: string
  apiToken: string
}

export const parseCredentials = () => {
  const urlParams = new URLSearchParams(window.location.search)
  return {
    clientId: urlParams.get("client_id") || "",
    apiToken: urlParams.get("api_token") || "",
  }
}
