import "./App.css"
import React from "react"
import { parseCredentials } from "./credentials"
import { ThemeProvider, createMuiTheme, responsiveFontSizes } from "@material-ui/core"
import { EnvironmentContext, environment } from "./EnvironmentContext"
import { EditorialNotificationsPage } from "./EditorialNotificationsPage"

const theme = responsiveFontSizes(
  createMuiTheme({
    palette: {
      secondary: { main: "#380183" },
    },
    overrides: {
      MuiRadio: {
        root: {
          paddingLeft: 6,
          paddingRight: 6,
          paddingTop: 3,
          paddingBottom: 3,
        }
      },
      MuiCheckbox: {
        root: {
          paddingLeft: 6,
          paddingRight: 6,
          paddingTop: 3,
          paddingBottom: 3,
        }
      }
    }
  }),
)

export const App = () => (
  <EnvironmentContext.Provider value={{ ...environment, credentials: parseCredentials() }}>
    <ThemeProvider theme={theme}>
      <div className="App">
        <EditorialNotificationsPage />
      </div>
    </ThemeProvider>
  </EnvironmentContext.Provider>
)
