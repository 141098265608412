const displayTimeZone = "America/New_York"
const displayLocale = "en-US"

export const toDisplayTime = (d: Date) =>
  d.toLocaleString(displayLocale, {
    timeZone: displayTimeZone,
    hour: "numeric",
    minute: "numeric",
    hour12: true,
    timeZoneName: "short",
  })

export const toDisplayDate = (d: Date) =>
  d.toLocaleString(displayLocale, {
    timeZone: displayTimeZone,
    year: "numeric",
    month: "long",
    day: "numeric",
  })

export const toDisplayYear = (d: Date) =>
  d.toLocaleString(displayLocale, {
    timeZone: displayTimeZone,
    year: "numeric",
  })

export const toDisplayMonthAndDay = (d: Date) =>
  d.toLocaleString(displayLocale, {
    timeZone: displayTimeZone,
    month: "long",
    day: "numeric",
  })

export const startOfDayDisplayed = (d: Date) => {
  let outcome = new Date(Date.parse(
    d.toLocaleString(displayLocale, {
      timeZone: displayTimeZone,
      year: "numeric",
      month: "numeric",
      day: "numeric",
      timeZoneName: "short"
    }))
  )
  return outcome;
}

export const toCustomISODate = (date: Date) => {
  const year = date.getFullYear()
  const month = ('0' + (date.getMonth() + 1)).slice(-2) 
  const day = ('0' + date.getDate()).slice(-2)
  const hour = ('0' + date.getHours()).slice(-2) 
  const minutes = ('0' + date.getMinutes()).slice(-2) 
  const seconds = ('0' + date.getSeconds()).slice(-2) 
  
  var toDate = year+"-"+month+"-"+day+"T"+hour+":"+minutes+":"+seconds
    return toDate
  }

export const toISODate = (date: Date) => date.toISOString().substr(0, 19)

export const addDays = (date: Date, days: number) => {
  return new Date(date.getTime() + days * 24 * 60 * 60 * 1000)
}
